import clsx from 'clsx'
import useTranslation from 'next-translate/useTranslation'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import React, {HTMLProps} from 'react'
import useFooterData from '../../../hooks/use-footer-data'
import defaultImageLoader from '../../../lib/default-image-loader'
import bishopSymbol from '../../../public/assets/bishop-logo-symbol-transparent.png'
import Container from '../../atoms/container'
import AmplifyreIcon from '../../atoms/icons/amplifyre-icon'
import Link from '../../atoms/link'
import SocialLink from '../../atoms/social-link'
import DynamicLink from '../../molecules/dynamic-link'
import Navigation from '../../molecules/navigation'
import {FooterSection} from '../../molecules/footer-section'
import LocationSection from '../../molecules/location-section'

export type FooterProps = HTMLProps<HTMLDivElement>

function IconLoader() {
  return <div className="w-6 h-6" />
}

const LinkedInIcon = dynamic(() => import('../../atoms/icons/linkedin-icon'), {
  ssr: false,
  loading: IconLoader,
})

const TwitterIcon = dynamic(() => import('../../atoms/icons/twitter-icon'), {
  ssr: false,
  loading: IconLoader,
})

const InstagramIcon = dynamic(
  () => import('../../atoms/icons/instagram-icon'),
  {
    ssr: false,
    loading: IconLoader,
  },
)

const FacebookIcon = dynamic(() => import('../../atoms/icons/facebook-icon'), {
  ssr: false,
  loading: IconLoader,
})

const MediumIcon = dynamic(() => import('../../atoms/icons/medium-icon'), {
  ssr: false,
  loading: IconLoader,
})

const Amplifyre = dynamic(() => import('../../atoms/icons/amplifyre-icon'), {
  ssr: false,
  loading: IconLoader,
})

export function Footer({children, className, ...props}: FooterProps) {
  const {t} = useTranslation('common')
  // note: this is a trade-off to reduce bundle size, js-yaml couldn't be
  // excluded from the client-side bundle otherwise
  const {
    general: {emailAddress, phoneNumber, secondaryPhoneNumber, locations},
    social: {
      linkedInUrl,
      facebookUrl,
      instagramUrl,
      twitterUrl,
      mediumUrl,
      amplifyreUrl,
    },
  } = useFooterData()

  return (
    <footer
      className={clsx(
        'relative w-full bottom-0 bg-extra-dark text-white pt-10 pb-16 sm:pt-10 sm:pb-10 grid grid-flow-row gap-8',
        className,
      )}
      {...props}
    >
      <Container className="grid justify-between grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-5 gap-y-8 lg:gap-y-0">
        <Container disableDefaultPadding>
          <Container disableDefaultPadding className="pb-2 -ml-1 lg:hidden ">
            <Image
              src={bishopSymbol}
              alt="Bishop &amp; Co. logo symbol"
              width={35}
              height={35}
              quality={100}
              layout="fixed"
              unoptimized
              loader={defaultImageLoader}
            />
          </Container>

          <FooterSection title={t('contact.title')}>
            <address className="grid grid-flow-row gap-1.5 md:gap-3 not-italic justify-items-start">
              <DynamicLink
                disableDefaultStyles
                className="text-white transition-colors hover:text-secondary "
                href={`mailto:${emailAddress}`}
              >
                {emailAddress}
              </DynamicLink>

              <DynamicLink
                disableDefaultStyles
                className="text-white transition-colors hover:text-secondary"
                href={`tel:${phoneNumber.replace(/ /gi, '')}`}
              >
                {phoneNumber}
              </DynamicLink>

              {secondaryPhoneNumber && (
                <DynamicLink
                  disableDefaultStyles
                  className="text-white transition-colors hover:text-secondary"
                  href={`tel:${secondaryPhoneNumber.replace(/ /gi, '')}`}
                >
                  {secondaryPhoneNumber}
                </DynamicLink>
              )}
            </address>
          </FooterSection>
        </Container>

        <FooterSection title={t('location.title')}>
          <LocationSection locations={locations} />
        </FooterSection>

        <FooterSection title={t('company.title')}>
          <Navigation
            direction="vertical"
            placement="footer"
            aria-label="Footer navigation"
          />
        </FooterSection>

        {(linkedInUrl ||
          facebookUrl ||
          instagramUrl ||
          twitterUrl ||
          mediumUrl ||
          amplifyreUrl) && (
          <FooterSection title={t('follow-us.title')}>
            <ul className="grid justify-start gap-1.5 md:gap-3 grid-flow-row-dense">
              {linkedInUrl && (
                <li>
                  <SocialLink
                    icon={<LinkedInIcon />}
                    href={linkedInUrl}
                    title={t('follow-us.linkedin-title')}
                    variant="secondary"
                  >
                    {t('follow-us.linkedin')}
                  </SocialLink>
                </li>
              )}

              {mediumUrl && (
                <li>
                  <SocialLink
                    icon={<MediumIcon />}
                    href={mediumUrl}
                    title={t('follow-us.medium-title')}
                    variant="secondary"
                  >
                    {t('follow-us.medium')}
                  </SocialLink>
                </li>
              )}

              {facebookUrl && (
                <li>
                  <SocialLink
                    icon={<FacebookIcon />}
                    href={facebookUrl}
                    title={t('follow-us.facebook-title')}
                    variant="secondary"
                  >
                    {t('follow-us.facebook')}
                  </SocialLink>
                </li>
              )}

              {instagramUrl && (
                <li>
                  <SocialLink
                    icon={<InstagramIcon />}
                    href={instagramUrl}
                    title={t('follow-us.instagram-title')}
                    variant="secondary"
                  >
                    {t('follow-us.instagram')}
                  </SocialLink>
                </li>
              )}

              {twitterUrl && (
                <li>
                  <SocialLink
                    icon={<TwitterIcon />}
                    href={twitterUrl}
                    title={t('follow-us.twitter-title')}
                    variant="secondary"
                  >
                    {t('follow-us.twitter')}
                  </SocialLink>
                </li>
              )}

              {amplifyreUrl && (
                <li>
                  <SocialLink
                    icon={<AmplifyreIcon />}
                    href={amplifyreUrl}
                    title={t('follow-us.amplifyre-title')}
                    variant="secondary"
                  >
                    {t('follow-us.amplifyre')}
                  </SocialLink>
                </li>
              )}
            </ul>
          </FooterSection>
        )}

        <FooterSection title={t('legal.title')}>
          <ul className="grid justify-start gap-1.5 md:gap-3 grid-flow-row-dense">
            <li>
              <Link
                fontWeight="normal"
                href="/privacy-policy"
                variant="secondary"
                disableActiveState
                prefetch={false}
              >
                {t('legal.privacy-policy')}
              </Link>
            </li>
            <li>
              <Link
                fontWeight="normal"
                href="/cookie-policy"
                variant="secondary"
                disableActiveState
                prefetch={false}
              >
                {t('legal.cookie-policy')}
              </Link>
            </li>
          </ul>
        </FooterSection>
      </Container>

      {children}
    </footer>
  )
}

export default Footer
