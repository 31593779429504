import clsx from 'clsx'
import useTranslation from 'next-translate/useTranslation'
import {HTMLProps} from 'react'
import Button from '../../atoms/button'
import Link, {FontWeight} from '../../atoms/link'

export interface NavigationProps extends HTMLProps<HTMLDivElement> {
  /**
   * Direction of menu items.
   *
   * @default 'horizontal'
   */
  direction?: 'vertical' | 'horizontal'
  /**
   * Placement of the navigation.
   *
   * @default 'header'
   */
  placement?: 'header' | 'footer'
  /**
   * Called when any of the links is clicked or touched.
   *
   * @default null
   */
  onLinkSelected?: (name?: string) => void
  /**
   * Class name passed down to the inner native list element.
   *
   * @default null
   */
  listClassName?: string
  /**
   * Determines whether navigation should be rendered as a mobile navigation.
   *
   * @default false
   */
  mobile?: boolean
}

export function Navigation({
  direction = 'horizontal',
  placement = 'header',
  onLinkSelected,
  listClassName,
  mobile,
  ...props
}: NavigationProps) {
  const {t} = useTranslation('common')
  const linkFontWeight: FontWeight =
    placement === 'header' ? 'semibold' : 'normal'
  const linkVariant = placement === 'header' ? 'primary' : 'secondary'
  const shouldDisplayActiveUnderline = placement === 'header'
  const disableActiveState = placement === 'footer'

  function handleClick(name?: string) {
    if (onLinkSelected) {
      onLinkSelected(name)
    }
  }

  return (
    <nav className="grid items-center" {...props}>
      <ul
        className={clsx(
          'grid',
          direction === 'horizontal'
            ? 'grid-flow-col justify-start'
            : 'grid-flow-row',
          placement === 'footer'
            ? 'text-white'
            : 'text-typography items-center',
          mobile
            ? 'gap-8'
            : direction === 'horizontal'
            ? 'gap-4 md:gap-6'
            : 'gap-1.5 md:gap-3',
          listClassName,
        )}
      >
        <li>
          <Link
            fontWeight={linkFontWeight}
            href="/"
            onClick={() => handleClick('home')}
            variant={linkVariant}
            shouldDisplayActiveUnderline={shouldDisplayActiveUnderline}
            disableActiveState={disableActiveState}
            prefetch={false}
          >
            {t('navigation.home')}
          </Link>
        </li>

        <li>
          <Link
            fontWeight={linkFontWeight}
            href="/services"
            onClick={() => handleClick('services')}
            variant={linkVariant}
            shouldDisplayActiveUnderline={shouldDisplayActiveUnderline}
            disableActiveState={disableActiveState}
            prefetch={false}
          >
            {t('navigation.services')}
          </Link>
        </li>

        <li>
          <Link
            fontWeight={linkFontWeight}
            href="/case-studies"
            onClick={() => handleClick('case-studies')}
            variant={linkVariant}
            shouldDisplayActiveUnderline={shouldDisplayActiveUnderline}
            disableActiveState={disableActiveState}
            prefetch={false}
          >
            {t('navigation.case-studies')}
          </Link>
        </li>

        <li>
          <Link
            fontWeight={linkFontWeight}
            href="/about-us"
            onClick={() => handleClick('about-us')}
            variant={linkVariant}
            shouldDisplayActiveUnderline={shouldDisplayActiveUnderline}
            disableActiveState={disableActiveState}
            prefetch={false}
          >
            {t('navigation.about-us')}
          </Link>
        </li>

        <li>
          <Link
            fontWeight={linkFontWeight}
            href="/careers"
            onClick={() => handleClick('careers')}
            variant={linkVariant}
            shouldDisplayActiveUnderline={shouldDisplayActiveUnderline}
            disableActiveState={disableActiveState}
            prefetch={false}
          >
            {t('navigation.careers')}
          </Link>
        </li>

        <li>
          <Link
            fontWeight={linkFontWeight}
            href="/partners"
            onClick={() => handleClick('partners')}
            variant={linkVariant}
            shouldDisplayActiveUnderline={shouldDisplayActiveUnderline}
            disableActiveState={disableActiveState}
            prefetch={false}
          >
            {t('navigation.partners')}
          </Link>
        </li>

        <li>
          {placement === 'header' ? (
            <Button
              variant="primary"
              className={clsx('block', mobile ? 'text-lg' : 'text-base')}
              href="/contact-us"
              renderAsLink
              onClick={() => handleClick('contact-us')}
              prefetch={false}
            >
              {t('navigation.contact-us')}
            </Button>
          ) : (
            <Link
              fontWeight={linkFontWeight}
              href="/contact-us"
              onClick={() => handleClick('contact-us')}
              variant={linkVariant}
              shouldDisplayActiveUnderline={shouldDisplayActiveUnderline}
              disableActiveState={disableActiveState}
              prefetch={false}
            >
              {t('navigation.contact-us')}
            </Link>
          )}
        </li>

        {placement === 'footer' && (
          <li>
            <Link
              fontWeight={linkFontWeight}
              href="/kkv-start-palyazat"
              onClick={() => handleClick('funded-projects')}
              variant={linkVariant}
              shouldDisplayActiveUnderline={shouldDisplayActiveUnderline}
              disableActiveState={disableActiveState}
              hrefLang="hu-HU"
              prefetch={false}
            >
              {t('navigation.funded-projects')}
            </Link>
          </li>
        )}
      </ul>
    </nav>
  )
}

export default Navigation
