import clsx from 'clsx'
import {Place} from '../../../lib/fetch-general-settings'
import {Container} from '../../atoms/container'
import {Paragraph} from '../../atoms/paragraph'
import LocationPlace from '../location-place'

type LocationContainerProps = {
  name: string
  places: Array<Place>
  className?: string
}

export function LocationContainer({
  name,
  places,
  className,
}: LocationContainerProps) {
  return (
    <Container disableDefaultPadding>
      <Paragraph className={clsx('font-bold text-white', className)}>
        {name}
      </Paragraph>

      {places?.length > 0 &&
        places.map(place => (
          <LocationPlace className="pb-2" place={place} key={place.address} />
        ))}
    </Container>
  )
}

export default LocationContainer
