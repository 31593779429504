import {Location} from '../../../lib/fetch-general-settings'
import {LocationContainer} from '../location-container'

type LocationSectionProps = {
  locations: Array<Location>
}

export function LocationSection({locations}: LocationSectionProps) {
  return (
    <address className="not-italic">
      {locations?.length > 0 &&
        locations.map(({name, places}) => (
          <LocationContainer name={name} places={places} key={name} />
        ))}
    </address>
  )
}

export default LocationSection
