import clsx from 'clsx'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import {PropsWithChildren} from 'react'
import defaultImageLoader from '../../lib/default-image-loader'
import bishopSymbol from '../../public/assets/bishop-logo-symbol-transparent.png'
import Container from '../../components/atoms/container'
import SEO, {SEOProps} from '../../components/atoms/seo'
import Header, {HeaderProps} from '../../components/molecules/header'
import MobileNavigation from '../../components/molecules/mobile-navigation'
import Footer from '../../components/organisms/footer'
import useCookieConsent from '../../hooks/use-cookie-consent'
import useMenu from '../../hooks/use-menu'
import {Box} from '../../components/atoms/box'

export interface PageLayoutProps extends SEOProps {
  /**
   * Class name(s) applied to the main content area.
   *
   * @default ''
   */
  className?: string
  /**
   * Determines whether default padding should be disabled or not.
   *
   * @default false
   */
  disableDefaultPadding?: boolean
  /**
   * Props passed to the header component.
   */
  headerProps?: HeaderProps
}

export function PageLayout({
  children,
  title = '',
  disableDefaultPadding,
  className,
  headerProps,
  ...props
}: PropsWithChildren<PageLayoutProps>) {
  const {t} = useTranslation('common')
  const [menuOpen] = useMenu()
  const [{dialogOpen}] = useCookieConsent()

  return (
    <>
      <SEO title={title} {...props} />

      <Header {...headerProps} />

      <MobileNavigation />

      <main
        className={clsx(
          'z-0',
          !disableDefaultPadding && 'md:pt-40 pt-28 pb-12 md:pb-24',
          'overflow-hidden min-h-near-screen',
          className,
        )}
        aria-hidden={menuOpen || dialogOpen ? 'true' : 'false'}
      >
        {children}
      </main>

      <Footer className="z-0">
        <Container className="flex sm:justify-end">
          <Container disableDefaultPadding className="hidden -ml-1 lg:block">
            <Image
              src={bishopSymbol}
              alt="Bishop &amp; Co. logo symbol"
              width={35}
              height={35}
              quality={100}
              layout="fixed"
              unoptimized
              loader={defaultImageLoader}
            />
          </Container>

          <Container disableDefaultPadding className="flex sm:justify-end">
            <Box
              component="span"
              className="self-center text-base text-current opacity-50"
            >
              {t('general.copyright', {currentYear: new Date().getFullYear()})}
            </Box>
          </Container>
        </Container>
      </Footer>
    </>
  )
}

export default PageLayout
