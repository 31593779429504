import clsx from 'clsx'
import {HTMLProps} from 'react'

export interface SocialLinkProps
  extends Omit<HTMLProps<HTMLAnchorElement>, 'rel' | 'target' | 'src'> {
  /**
   * Link variant which determines which colors the component will use.
   *
   * @default 'primary'
   */
  variant?: 'primary' | 'secondary'
  /**
   * Icon to be displayed next to the social link text.
   *
   * @default null
   */
  icon?: React.ReactNode
}

export function SocialLink({
  className,
  icon,
  children,
  variant = 'primary',
  ...props
}: SocialLinkProps) {
  return (
    <a
      target="_blank"
      rel="noreferrer noopener"
      className={clsx(
        'inline-grid gap-2 grid-flow-col text-white',
        variant === 'primary' ? 'hover:text-primary' : 'hover:text-secondary',
        'transition-colors',
        className,
      )}
      {...props}
    >
      {icon}

      <span>{children}</span>
    </a>
  )
}

export default SocialLink
