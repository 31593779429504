import {useContext} from 'react'
import FooterContext from '../../contexts/footer-context'

export function useFooterData() {
  const footerContext = useContext(FooterContext)

  if (!footerContext) {
    throw new Error(`'useFooterData' should be used in a FooterContext.`)
  }

  return footerContext
}

export default useFooterData
