import clsx from 'clsx'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import {useCallback, useEffect, useState} from 'react'
import useMenu from '../../../hooks/use-menu'
import defaultImageLoader from '../../../lib/default-image-loader'
import bishopLogo from '../../../public/assets/bishop-logo.png'
import Button from '../../atoms/button'
import Container, {ContainerProps} from '../../atoms/container'
import CloseIcon from '../../atoms/icons/close-icon'
import MenuIcon from '../../atoms/icons/menu-icon'
import Link from '../../atoms/link'
import Navigation from '../navigation'

export interface HeaderProps extends Omit<ContainerProps, 'ref'> {
  /**
   * Class name applied to the main header component.
   *
   * @default null
   */
  wrapperClassName?: string
  /**
   * Determines whether default classes should be disabled or not.
   *
   * @default false
   */
  disableDefaultStyles?: boolean
  /**
   * Determines whether or not header should be hidden.
   *
   * @default false
   */
  hidden?: boolean
  /**
   * Determines whether or not the default scroll behaviour should be disabled.
   * The default scroll behaviour applies classes to the header when a certain
   * scroll position is reached.
   *
   * @default false
   */
  disableScrollBehaviour?: boolean
}

export function Header({
  children,
  className,
  wrapperClassName,
  disableDefaultStyles,
  disableScrollBehaviour,
  hidden,
  ...props
}: HeaderProps) {
  const [open, setOpen] = useMenu()
  const [showHeaderStyles, setShowHeaderStyles] = useState(false)
  const {t} = useTranslation('common')

  const showHeaderBackgroundClasses =
    (!disableDefaultStyles && showHeaderStyles) ||
    (!disableDefaultStyles && disableScrollBehaviour)

  function openMenu() {
    setOpen(true)
  }

  function closeMenu() {
    setOpen(false)
  }

  const handleScroll = useCallback(
    function handleScroll(event: Event) {
      if (event.target instanceof Document && event.target.scrollingElement) {
        const {scrollTop, clientWidth} = event.target.scrollingElement
        const threshold = clientWidth >= 1024 ? 80 : 35

        if (scrollTop >= threshold && !showHeaderStyles) {
          setShowHeaderStyles(true)
        } else if (scrollTop < threshold && showHeaderStyles) {
          setShowHeaderStyles(false)
        }
      }
    },
    [showHeaderStyles],
  )

  useEffect(() => {
    if (typeof window !== 'undefined' && !disableScrollBehaviour) {
      window.addEventListener('scroll', handleScroll, {passive: true})
    }

    return () => window.removeEventListener('scroll', handleScroll)
  }, [disableScrollBehaviour, handleScroll])

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        setOpen(false)
      }
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [setOpen])

  return !hidden ? (
    <header
      className={clsx(
        'fixed top-0 left-0 right-0 z-50 transform-gpu transition-colors border-solid border-b-1 bg-white backdrop-blur-lg bg-opacity-75',
        showHeaderBackgroundClasses && !open
          ? 'border-gray-200'
          : 'border-transparent',
        wrapperClassName,
      )}
    >
      <Container
        className={clsx(
          'grid grid-flow-col justify-between items-center py-4',
          className,
        )}
        {...props}
      >
        <Link
          href="/"
          className="hidden md:grid items-center"
          shouldDisplayActiveUnderline={false}
        >
          <Image
            src={bishopLogo}
            alt="Bishop &amp; Co. logo"
            width={130}
            height={37.7}
            quality={100}
            layout="fixed"
            loader={defaultImageLoader}
            priority
          />
        </Link>

        <Link
          href="/"
          className="grid items-center md:hidden"
          onClick={() => setOpen(false)}
          shouldDisplayActiveUnderline={false}
        >
          <Image
            src={bishopLogo}
            alt="Bishop &amp; Co. logo"
            width={130}
            height={37.7}
            quality={100}
            layout="fixed"
            loader={defaultImageLoader}
            priority
          />
        </Link>

        <span className="block md:hidden w-12" />

        <Navigation
          className="hidden md:block"
          aria-label="Main desktop navigation"
        />

        <Button
          onClick={() => (open ? closeMenu() : openMenu())}
          padding="same"
          variant="borderless"
          aria-label={open ? t('general.close-menu') : t('general.open-menu')}
          className="block md:hidden"
        >
          {open ? <CloseIcon /> : <MenuIcon />}
        </Button>
      </Container>
    </header>
  ) : null
}

export default Header
