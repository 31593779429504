import {NextSeo, NextSeoProps} from 'next-seo'
import defaultSeoProps from '../../../next-seo.config'
import { useRouter } from 'next/router'

export interface SEOProps extends NextSeoProps {
  /**
   * Determines whether Open Graph image URL should be automatically prefixed
   * with the site's domain.
   *
   * @default true
   */
  prefixImageUrl?: boolean
  /**
   * Sets the OpenGraph type of the website. If it's set to article, it will generally
   * show the description in the preview.
   *
   * @default website
   */
  type?: "website" | "article"
}

export function SEO({
  defaultTitle = 'Bishop & Co.',
  titleTemplate = '%s | Bishop & Co.',
  openGraph,
  type = "website",
  prefixImageUrl = true,
  ...props
}: SEOProps) {

  return (
    <NextSeo
      defaultTitle={defaultTitle}
      titleTemplate={titleTemplate}
      openGraph={{
        type: type,
        url: `${defaultSeoProps.openGraph.url}/${openGraph?.url || ''}`,
        images: openGraph?.images?.map(image => ({
          url: `${prefixImageUrl ? defaultSeoProps.openGraph.url : ''}${
            image.url
          }`,
        })),
      }}
      {...props}
    />
  )
}

export default SEO
