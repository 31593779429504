import {Place} from '../../../lib/fetch-general-settings'
import {Container} from '../../atoms/container'
import {Paragraph} from '../../atoms/paragraph'

type LocationAddressProps = {
  place: Place
  className?: string
}

export function LocationAddress({place, className}: LocationAddressProps) {
  const placeKeys: Array<keyof Place> = [
    'country',
    'state',
    'city',
    'address',
    'building',
  ]

  return (
    <Container disableDefaultPadding className={className}>
      {placeKeys.map(key => {
        const value = place[key]

        return (
          value && (
            <Paragraph key={key} className="text-white">
              {value}
            </Paragraph>
          )
        )
      })}
    </Container>
  )
}

export default LocationAddress
