import {PropsWithChildren} from 'react'
import {Heading} from '../../atoms/heading'
import clsx from 'clsx'

type FooterSectionProps = {
  title: string
  className?: string
  headerClassName?: string
}

export function FooterSection({
  title,
  className,
  headerClassName,
  children,
}: PropsWithChildren<FooterSectionProps>) {
  return (
    <div className={clsx('text-lg', className)}>
      <Heading
        component="span"
        variant="h4"
        className={clsx('text-white pb-4 lg:pb-6', headerClassName)}
      >
        {title}
      </Heading>
      {children}
    </div>
  )
}

export default FooterSection
