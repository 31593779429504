import clsx from 'clsx'
import {createElement, ForwardedRef, forwardRef, HTMLProps} from 'react'

export interface BoxProps extends HTMLProps<Omit<HTMLDivElement, 'ref'>> {
  /**
   * Native HTML component to render.
   *
   * @default 'div'
   */
  component?: string
}

export const Box = forwardRef(function Container(
  {component = 'div', className, children, ...props}: BoxProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return createElement(
    component,
    {
      className: clsx(className),
      ref,
      ...props,
    },
    children,
  )
})

export default Box
