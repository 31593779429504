import {useContext} from 'react'
import MenuContext from '../../contexts/menu-context'

export function useMenu() {
  const context = useContext(MenuContext)

  if (!context) {
    throw new Error(`'useMenu' should be used in a MenuContext.`)
  }

  return context
}

export default useMenu
