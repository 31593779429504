import clsx from 'clsx'
import {HTMLProps} from 'react'
import Link, {LinkProps} from '../../atoms/link'

export type DynamicLinkProps = HTMLProps<HTMLAnchorElement> &
  Pick<LinkProps, 'disableDefaultStyles'>

export function DynamicLink({
  children,
  href,
  className,
  disableDefaultStyles,
  ...props
}: DynamicLinkProps) {
  if (href && href.toString().startsWith('/')) {
    return (
      <Link
        href={href}
        fontWeight="normal"
        active
        shouldDisplayActiveUnderline={false}
        className={clsx('hover:underline', className)}
        disableDefaultStyles={disableDefaultStyles}
        {...props}
      >
        {children}
      </Link>
    )
  }

  return (
    <a
      className={clsx(
        disableDefaultStyles
          ? className
          : 'text-primary hover:text-primary active:text-primary-dark hover:underline',
      )}
      href={href?.toString()}
      {...props}
    >
      {children}
    </a>
  )
}

export default DynamicLink
