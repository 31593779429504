import clsx from 'clsx'
import {HTMLProps} from 'react'
import useMenu from '../../../hooks/use-menu'
import Container from '../../atoms/container'
import Navigation from '../navigation'

export function MobileNavigation({
  className,
  ...props
}: HTMLProps<HTMLDivElement>) {
  const [open, setOpen] = useMenu()

  return (
    <div
      className={clsx(
        'mobile-menu fixed w-full h-full top-0 left-0 right-0 bottom-0 bg-white md:hidden z-40 pt-16',
        open && 'open',
        className,
      )}
      {...props}
    >
      <Container className="grid justify-center text-xl text-center py-10 overflow-auto h-full">
        <Navigation
          className="items-start"
          direction="vertical"
          mobile
          onLinkSelected={() => setOpen(false)}
          aria-label="Main mobile navigation"
        />
      </Container>
    </div>
  )
}

export default MobileNavigation
