import {createContext, PropsWithChildren} from 'react'
import {GeneralSettingsProps} from '../../lib/fetch-general-settings'
import {SocialLinksProps} from '../../lib/fetch-social-links'

export type FooterDataStateProps = {
  social: SocialLinksProps
  general: GeneralSettingsProps
}

export const FooterContext = createContext<FooterDataStateProps | null>(null)

export interface FooterProviderProps {
  value: FooterDataStateProps
}

export function FooterProvider({
  value,
  children,
}: PropsWithChildren<FooterProviderProps>) {
  return (
    <FooterContext.Provider value={value}>{children}</FooterContext.Provider>
  )
}

export default FooterContext
